/* This stylesheet generated by Transfonter (https://transfonter.org) on October 21, 2017 6:04 AM */

@font-face {
    font-family: 'YesevaOne';
    src: url("../fonts/YesevaOne-Regular.eot");
    src: url("../fonts/YesevaOne-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/YesevaOne-Regular.ttf") format("truetype"), url("../fonts/YesevaOne-Regular.svg#YesevaOne-Regular") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url("../fonts/Lato-Regular.eot");
    src: url("../fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"),  url("../fonts/Lato-Regular.ttf") format("truetype"), url("../fonts/Lato-Regular.svg#Lato-Regular") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url("../fonts/Lato-Bold.eot");
    src: url("../fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato-Bold.woff") format("woff"), url("../fonts/Lato-Bold.ttf") format("truetype"), url("../fonts/Lato-Bold.svg#Lato-Bold") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-BoldItalic.eot');
    src: url('../fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-BoldItalic.woff2') format('woff2'),
        url('../fonts/Lato-BoldItalic.woff') format('woff'),
        url('../fonts/Lato-BoldItalic.ttf') format('truetype'),
        url('../fonts/Lato-BoldItalic.svg#Lato-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

/*
@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-Light.eot");
    src: url("../fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat-Light.woff") format("woff"), url("../fonts/Montserrat-Light.ttf") format("truetype"), url("../fonts/Montserrat-Light.svg#Montserrat-Light") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-Regular.eot");
    src: url("../fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat-Regular.ttf") format("truetype"), url("../fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.eot');
    src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBold.woff') format('woff'),
        url('../fonts/Montserrat-SemiBold.ttf') format('truetype'),
        url('../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-Bold.eot");
    src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.ttf") format("truetype"), url("../fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ExtraBold.eot');
    src: url('../fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
        url('../fonts/Montserrat-ExtraBold.woff') format('woff'),
        url('../fonts/Montserrat-ExtraBold.ttf') format('truetype'),
        url('../fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}*/


@font-face {
    font-family: 'Gotham';
    src: url("../fonts/Gotham-Bold.eot");
    src: url("../fonts/Gotham-Bold.eot?#iefix") format("embedded-opentype"),  url("../fonts/Gotham-Bold.ttf") format("truetype"), url("../fonts/Gotham-Bold.svg#Gotham-Bold") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham-medium-webfont.eot');
    src: url('../fonts/gotham-medium-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham-medium-webfont.ttf')  format('truetype'),
        url('../fonts/gotham-medium-webfont.svg#gothammedium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham-book-webfont.eot');
    src: url('../fonts/gotham-book-webfont.eot?#iefix') format('embedded-opentype'),
            url('../fonts/gotham-book-webfont.ttf')  format('truetype'),
            url('../fonts/gotham-book-webfont.svg#gothambook') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Whitney';
    src: url('../fonts/Whitney-Book.eot');
    src: url('../fonts/Whitney-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Whitney-Book.woff2') format('woff2'),
        url('../fonts/Whitney-Book.woff') format('woff'),
        url('../fonts/Whitney-Book.ttf') format('truetype'),
        url('../fonts/Whitney-Book.svg#Whitney-Book') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Lobster';
  src: url('../fonts/Lobster-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../fonts/Helvetica-Neue-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "iconfont";
  src: url('../fonts/iconfonts/iconfont.eot');
  src: url('../fonts/iconfonts/iconfont.eot?#iefix') format('eot'),
       url('../fonts/iconfonts/iconfont.woff2') format('woff2'),
       url('../fonts/iconfonts/iconfont.woff') format('woff'),
       url('../fonts/iconfonts/iconfont.ttf') format('truetype'),
       url('../fonts/iconfonts/iconfont.svg#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}
